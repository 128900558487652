import { Haptics, ImpactStyle } from "@capacitor/haptics";
import loadConfig from "@Web3WalletConfig/config";
export const hoeRockAnimation = async (id) => {
  const canvas = document.getElementById(id);
  const ctx = canvas.getContext("2d",);
  // ctx.imageSmoothingEnabled = true;
  // ctx.mozImageSmoothingEnabled = true;
  // ctx.webkitImageSmoothingEnabled = true;
  // ctx.msImageSmoothingEnabled = true;
  

  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;


   // 获取设备像素比
   const dpr = window.devicePixelRatio || 1;

   // 获取 canvas 的初始大小
   const rect = canvas.getBoundingClientRect();

   // 设置 canvas 的宽高为其 CSS 宽高乘以 DPR
   canvas.width = rect.width * dpr;
   canvas.height = rect.height * dpr;

   // 设置 canvas 的 CSS 宽高
   canvas.style.width = `${rect.width}px`;
   canvas.style.height = `${rect.height}px`;

   // 将缩放上下文
   ctx.scale(dpr, dpr);

    console.log("rectrectrectrect", rect);

  const hoeImageSrc = require(`@images/${loadConfig.theme}/hoe.png`); // Path to your hoe image
  const rockImageSrcs = [require(`@images/${loadConfig.theme}/stone_1.png`), require(`@images/${loadConfig.theme}/stone_2.png`)]; // Paths to your rock images
  const hitSoundSrc = require(`@images/${loadConfig.theme}/tap.mp3`); // Path to your hit sound effect

  let hoeImage = new Image();
  hoeImage.src = hoeImageSrc;

  const rockImages = [];
  let particles = [];
  let audioContext;
  let hitSoundBuffer;

  hoeImage.onload = () => {
    loadRockImages(rockImageSrcs, () => {
      initializeAudio();
      requestAnimationFrame(animate);
    });
  };

  // Load rock images
  function loadRockImages(imageSources, callback) {
    let loadedCount = 0;
    imageSources.forEach((src, index) => {
      const img = new Image();
      img.onload = () => {
        loadedCount++;
        if (loadedCount === imageSources.length) {
          callback();
        }
      };
      img.src = src;
      rockImages[index] = img;
    });
  }

  let hoeX = window.innerWidth - 55;
  let hoeY = window.innerHeight / 2 - 50;
  let hoeAngle = 0;
  const hoeScale = 0.3; // Scale factor for the hoe image

  // Set custom splatter center point
  let splatterCenterX = window.innerWidth / 2;
  let splatterCenterY = window.innerHeight / 2 - 25;

  function animateHoe() {
    let start = null;
    const duration = 50;
    const startAngle = 0;
    const endAngle = -(Math.PI / 4);

    function step(timestamp) {
      if (!start) start = timestamp;
      let progress = (timestamp - start) / duration;

      if (progress < 1) {
        hoeAngle = startAngle + (endAngle - startAngle) * progress;
        requestAnimationFrame(step);
      } else {
        hoeAngle = endAngle;
        playHitSound();
        triggerVibration();
        triggerRockSplatter(splatterCenterX, splatterCenterY); // Trigger rock splatter at the custom center point
        setTimeout(() => {
          resetHoe();
        }, 10);
      }
      draw();
    }
    requestAnimationFrame(step);
  }

  function resetHoe() {
    let start = null;
    const duration = 100;
    const startAngle = hoeAngle;
    const endAngle = 0;

    function step(timestamp) {
      if (!start) start = timestamp;
      let progress = (timestamp - start) / duration;

      if (progress < 1) {
        hoeAngle = startAngle + (endAngle - startAngle) * progress;
        requestAnimationFrame(step);
      } else {
        hoeAngle = endAngle;
      }
      draw();
    }
    requestAnimationFrame(step);
  }

  async function triggerVibration() {
    // navigator.vibrate = navigator.vibrate || navigator.webkitVibrate || navigator.mozVibrate || navigator.msVibrate;

    // if (navigator.vibrate) {
    //   // 振动 200 毫秒
    //   navigator.vibrate(200);
    // }

    try {
      await Haptics.impact({ style: ImpactStyle.Heavy });
    } catch (err) {}
  }

  function initializeAudio() {
    audioContext = new (window.AudioContext || window.webkitAudioContext)();
    fetch(hitSoundSrc)
      .then((response) => response.arrayBuffer())
      .then((data) => audioContext.decodeAudioData(data))
      .then((buffer) => {
        hitSoundBuffer = buffer;
      });
  }

  // Play hit sound
  function playHitSound() {
    if (hitSoundBuffer) {
      const source = audioContext.createBufferSource();
      source.buffer = hitSoundBuffer;
      source.connect(audioContext.destination);
      source.start(0);
    }
  }

  // Particle constructor function
  function Particle(x, y) {
    this.x = x;
    this.y = y;
    this.size = Math.random() * 20 + 10; // Adjust size as needed
    const speed = Math.random() * 5 + 5; // Speed fosr splatter effect
    const angle = Math.random() * Math.PI; // Angle for splatter effect

    // Randomly determine direction (left or right)
    if (Math.random() > 0.5) {
      this.speedX = speed * Math.cos(angle);
    } else {
      this.speedX = -speed * Math.cos(angle);
    }

    this.speedY = -speed * Math.sin(angle); // Initial speed upward
    this.gravity = 0.2; // Positive gravity for realistic effect
    this.image = rockImages[Math.floor(Math.random() * rockImages.length)]; // Randomly choose an image
    this.life = 0;
    this.maxLife = Math.random() * 100 + 100;
    this.angle = Math.random() * Math.PI * 2;
    this.spin = Math.random() * 0.2 - 0.1;
  }

  Particle.prototype.update = function () {
    this.speedY += this.gravity;
    this.x += this.speedX;
    this.y += this.speedY;
    this.angle += this.spin;
    this.life++;
    if (this.life > this.maxLife) {
      particles.splice(particles.indexOf(this), 1);
    }
  };

  Particle.prototype.draw = function () {
    ctx.save();
    ctx.translate(this.x, this.y);
    ctx.rotate(this.angle);
    ctx.drawImage(this.image, -this.size / 2, -this.size / 2, this.size, this.size);
    ctx.restore();
  };

  // Trigger rock splatter
  function triggerRockSplatter(x, y) {
    for (let i = 0; i < 2; i++) {
      particles.push(new Particle(x, y));
    }
  }

  // Draw function
  function draw() {
    ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas

    // Draw hoe
    ctx.save();
    ctx.translate(hoeX, hoeY);
    ctx.rotate(hoeAngle);
    ctx.scale(hoeScale, hoeScale); // Apply scale to the hoe image
    ctx.drawImage(hoeImage, -hoeImage.width / 2, -hoeImage.height / 2);
    ctx.restore();

    // Update and draw particles
    particles.forEach((particle) => {
      particle.update();
      particle.draw();
    });
  }

  // Animation loop
  function animate() {
    draw();
    requestAnimationFrame(animate);
  }

  animate();
  return animateHoe;
};
