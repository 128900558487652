var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "wallet page",
    class: _vm.isMobile && 'wap',
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/inset-bg.jpg`)})`
    }
  }, [_c('div', {
    staticClass: "wallet-main"
  }, [_vm._l(_vm.list, function (citem, index) {
    return citem.assets.length > 0 ? _c('div', {
      staticClass: "token-items"
    }, [_c('div', {
      staticClass: "token-title"
    }, [_c('img', {
      attrs: {
        "src": citem.icon
      }
    }), _vm._v(_vm._s(citem.name) + " ")]), _vm._l(citem.assets, function (item, index) {
      return _c('div', {
        staticClass: "token-item",
        on: {
          "click": function ($event) {
            return _vm.onDetail(citem, item);
          }
        }
      }, [_c('div', {
        staticClass: "balance-info"
      }, [_c('div', {
        staticClass: "balance__type"
      }, [_c('img', {
        attrs: {
          "src": item.icon
        }
      }), _c('div', {
        staticClass: "balance__name"
      }, [_vm._v(" " + _vm._s(_vm._f("strToUpperCase")(item.name)) + " ")])]), _c('div', {
        staticClass: "balance__num"
      }, [_vm._v(_vm._s(_vm._f("nFormatter")(item.balance, 2)))])])]);
    })], 2) : _vm._e();
  }), _c('div', {
    staticClass: "adv",
    on: {
      "click": function ($event) {
        return _vm.jumpTo('/bioMatrixScan');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "https://static.tapcoins.app/taskimg/wallet_adv01.png"
    }
  })])], 2), _c('div', {
    staticClass: "mMenu"
  }, [_c('m-menu')], 1)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };