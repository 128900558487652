import Vue from "vue";
import vailcode from "@utils/errcode";
import cookie from "js-cookie";
import querystring from "querystring";

export default {
  namespaced: true,
  state: {
    botList: []
  },
  mutations: {
    setBotList: (state, payload) => {
      state.botList = payload;
    },
  },
  actions: {
    getBotList:async ({ state, commit }) => {
        const rs = await Vue.prototype.$http.post("/bot/setting",{});
        vailcode(rs, () => {
            console.log("getBotList",rs.data)
          commit("setBotList", rs.data);
        });
    },
    doUpdateBot:async ({ state, commit },id) => {
      const rs = await Vue.prototype.$http.post("/bot/update",{level});
      vailcode(rs, () => {
        commit("user/setUserData", rs.data, {root: true});
      });
  },
  },
  getters: {},
};
