export default {
  data() {
    return {
      dots: Array.from({
        length: 8
      })
    };
  },
  props: {
    color: {
      type: String,
      default: "#fff"
    }
  },
  methods: {},
  mounted() {},
  beforeDestroy() {}
};