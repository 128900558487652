import { createNamespacedHelpers } from "vuex";
import { v4 as uuidv4 } from 'uuid';
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const bioMatrixScanVuex = createNamespacedHelpers("bioMatrixScan");
export default {
  name: "bitgetWalletScanDetail",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting']),
    ...userVuex.mapState(["userData"]),
    ...bioMatrixScanVuex.mapState(['code'])
  },
  data() {
    return {
      url: 'https://verify.biomatrix.ai/face-scan?channelCode=HVGZ2R&requestId=',
      uid: '',
      iframeUrl: ''
    };
  },
  beforeRouteLeave(to, from, next) {
    this.uid = '';
    this.iframeUrl = '';
    this.$nextTick(() => {
      next();
    });
  },
  watch: {},
  async mounted() {
    if (!this.code) {
      this.setCode(uuidv4());
      this.setShowPop(true);
    }
    this.uid = this.userData.tg_id;
    this.iframeUrl = this.url + this.code + '&uid=' + this.uid;
  },
  methods: {
    ...bioMatrixScanVuex.mapMutations(['setCode', 'setShowPop'])
  }
};