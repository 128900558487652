import Vue from "vue";
import vailcode from "@utils/errcode";
import cookie from "js-cookie";
import querystring from "querystring";

export default {
  namespaced: true,
  state: {
    categoryList: [],
    taskList:{},
    selectTab:0,
    advList:[],
    taskInfo:{}
  },
  mutations: {
    setSelectTab: (state, payload) => {
      state.selectTab = payload;
    },
    setCategoryList: (state, payload) => {
      state.categoryList = payload;
    },
    setTaskList: (state, payload) => {
      Vue.set(state.taskList, payload.type, payload.list)
    },
    setTaskInfo: (state, payload) => {
      state.taskInfo = payload
    },
    setAdvList: (state, payload) => {
      state.advList = payload.list;
    },
    updateTask:(state, payload) =>{
      state.taskList[state.selectTab].forEach((item,index) => {
        if(item.id == payload.id){
          state.taskList[state.selectTab][index].completion_count ++;
          state.taskList[state.selectTab][index].completed = 1;
        }
      });
    },
  },
  actions: {
    getCategoryList:async ({ state, commit }) => {
        const rs = await Vue.prototype.$http.post("/app/categories",{});
        vailcode(rs, () => {
          commit("setCategoryList", rs.data);
        });
    },
    getTaskList:async ({ state, commit },[categoryId,callback,failcallback]) => {
      const rs = await Vue.prototype.$http.post("/app/tasks",{"categoryId":categoryId});
      vailcode(rs, () => {
        commit("setTaskList", {type:categoryId,list:rs.data});
        typeof callback=='function' && callback(rs.data)
      },
      ()=>{
        typeof failcallback=='function' && failcallback(rs)
      });
    },
    getTaskInfo:async ({ state, commit },[taskId,callback,failcallback]) => {
      const rs = await Vue.prototype.$http.post("/app/task/info",{"taskId":taskId});
      vailcode(rs, () => {
        commit("setTaskInfo", rs.data);
        typeof callback=='function' && callback(rs.data)
      },
      ()=>{
        typeof failcallback=='function' && failcallback(rs)
      });
    },
    getAdvList:async ({ state, commit },[callback,failcallback]) => {
      const rs = await Vue.prototype.$http.post("/app/advs",{});
      vailcode(rs, () => {
        commit("setAdvList", rs.data);
        typeof callback=='function' && callback(rs.data)
      },
      ()=>{
        typeof failcallback=='function' && failcallback(rs)
      });
    },
    taskUpgrade:async ({ state, commit },[taskId,callback,failcallback]) => {
      const rs = await Vue.prototype.$http.post("/app/task/complete",{"taskId":taskId});
      vailcode(
        rs,
        () => {
          if(rs.data.taskInfo){
            commit("updateTask",rs.data.taskInfo)
          }
          typeof callback=='function' && callback(rs.data)
        },
        ()=>{
          typeof failcallback=='function' && failcallback(rs)
        }
      );
    },
  },
  getters: {},
};
