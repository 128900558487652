import loading from './myLoading.vue'

let instance = null
export default {
    install(Vue) {
        if (!instance) {
            let loadingCompoent = Vue.extend(loading)
            instance = new loadingCompoent({
                el: document.createElement('div')
            })
            document.body.appendChild(instance.$el)
        }
        instance.isShow = false
        let customMethods = {
            show() {
                instance.isShow = true
            },
            hide() {
                instance.isShow = false
            }
        }

        if (!Vue.$loading) {
            Vue.$loading = customMethods
            Vue.prototype.$loading = Vue.$loading
        }
    }
}