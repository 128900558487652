import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
export default {
  name: "bitgetWalletScanDetail",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting']),
    ...userVuex.mapState(["userData"])
  },
  data() {
    return {
      url: 'https://verify.biomatrix.ai/face-scan?requestid=',
      code: ''
    };
  },
  beforeRouteLeave(to, from, next) {
    this.code = '';
    this.$nextTick(() => {
      next();
    });
  },
  watch: {},
  async mounted() {
    let that = this;
    that.code = '8f3cccfe-988c-41c7-9cca-bf89cc8ed1f8&uid=2037656313';
    // if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    //   navigator.mediaDevices.getUserMedia({ video: true })
    //       .then(function(stream) {
    //           // 这里可以处理视频流
    //         that.code = '8f3cccfe-988c-41c7-9cca-bf89cc8ed1f8&uid=2037656313'
    //       })
    //       .catch(function(err) {
    //           console.log("获取摄像头失败: " + err);
    //       });
    // }
  },

  methods: {}
};