import Vue from "vue";
import vailcode from "@utils/errcode";
import cookie from "js-cookie";
import querystring from "querystring";

export default {
  namespaced: true,
  state: {
    categoryList: [],
    taskList:[],
    taskInfo:{}
  },
  mutations: {
    setCategoryList: (state, payload) => {
      state.categoryList = payload;
    },
    setTaskList: (state, payload) => {
      state.taskList = payload
    },
    setTaskInfo: (state, payload) => {
      state.taskInfo = payload
    },
    updateTask:(state, payload) =>{
      state.categoryList[payload.index].sub.forEach((item,index) => {
        if(item.id == payload.id){
          state.categoryList[payload.index].sub[index].completed = 1;
        }
      });
    },
  },
  actions: {
    getCategoryList:async ({ state, commit }) => {
        const rs = await Vue.prototype.$http.post("/achv/categories",{});
        vailcode(rs, () => {
          commit("setCategoryList", rs.data);
        });
    },
    getTaskList:async ({ state, commit },[callback,failcallback]) => {
      const rs = await Vue.prototype.$http.post("/achv/items",{});
      vailcode(rs, () => {
        commit("setTaskList", rs.data);
        let newCacategoryList = []
        state.categoryList.forEach((item,index)=>{
          item.sub = []
          state.taskList.forEach((itm,idx)=>{
            if(itm.category_id == item.id){
              item.sub.push(itm)
            }
          })
          newCacategoryList.push(item)
        })
        commit("setCategoryList", newCacategoryList);
        typeof callback=='function' && callback(rs.data)
      },
      ()=>{
        typeof failcallback=='function' && failcallback(rs)
      });
    },
    getTaskInfo:async ({ state, commit },[id,callback,failcallback]) => {
      const rs = await Vue.prototype.$http.post("/achv/item/info",{"id":id});
      vailcode(rs, () => {
        commit("setTaskInfo", rs.data);
        typeof callback=='function' && callback(rs.data)
      },
      ()=>{
        typeof failcallback=='function' && failcallback(rs)
      });
    },
    taskUpgrade:async ({ state, commit },[itemId,value,address,index,callback,failcallback]) => {
      const rs = await Vue.prototype.$http.post("/achv/complete",{"itemId":itemId,"value":value,"address":address});
      vailcode(
        rs,
        () => {
          if(rs.data.itemInfo){
            commit("updateTask",{id:itemId,index:index})
          }
          typeof callback=='function' && callback(rs.data)
        },
        ()=>{
          typeof failcallback=='function' && failcallback(rs)
        }
      );
    },
  },
  getters: {},
};
