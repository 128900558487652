import Vue from "vue";
import vailcode from "@utils/errcode";
import cookie from "js-cookie";
import querystring from "querystring";

export default {
  namespaced: true,
  state: {
    summary: {},
    steps:[],
    tomorrowDistance:0,
    inviteSteps:[],
    inviteTomorrowDistance:0
  },
  mutations: {
    setSummary: (state, payload) => {
      state.summary = payload;
    },
    setSteps: (state, payload) => {
      state.steps = payload;
    },
    setTomorrowDistance: (state, payload) => {
      state.tomorrowDistance = payload;
    },
    setInviteSteps: (state, payload) => {
      state.inviteSteps = payload;
    },
    setInviteTomorrowDistance: (state, payload) => {
      state.inviteTomorrowDistance = payload;
    },
  },
  actions: {
    getSummary:async ({ state, commit }) => {
      let time = parseInt(new Date().getTime() / 1000)
      if(time - 10 > (state.summary.time || 0)){
          const rs = await Vue.prototype.$http.post("/daily/summary",{});
          vailcode(rs, () => {
            let time = parseInt(new Date().getTime() / 1000)
            commit("setSummary", {time,...rs.data});
          });
      }
    },
    getSteps:async ({ state, commit },[type,callback,failcallback]) => {
      const rs = await Vue.prototype.$http.post("/daily/steps",{"type":type});
      vailcode(rs, () => {
        if(type == 1){
          commit("setSteps", rs.data.steps);
          commit("setTomorrowDistance", rs.data.tomorrow_distance);
        }
        else{
          commit("setInviteSteps", rs.data.steps);
          commit("setInviteTomorrowDistance", rs.data.tomorrow_distance);
        }
        typeof callback=='function' && callback(rs.data)
      },
      ()=>{
        typeof failcallback=='function' && failcallback(rs)
      });
    },
    updatedDaily:async ({ state, commit },[type,multiple,callback,failcallback]) => {
      const rs = await Vue.prototype.$http.post("/daily/complete",{"type":type,multiple});
      vailcode(
        rs,
        () => {
          commit("setSteps",rs.data.steps)
          commit("setTomorrowDistance", rs.data.tomorrow_distance);
          typeof callback=='function' && callback(rs.data)
        },
        ()=>{
          typeof failcallback=='function' && failcallback(rs)
        }
      );
    },
    updatedClaim:async ({ state, commit },[type,step,callback,failcallback]) => {
      const rs = await Vue.prototype.$http.post("/daily/claim",{"type":type,"step":step});
      vailcode(
        rs,
        () => {
          commit("setInviteSteps", rs.data.steps);
          commit("setInviteTomorrowDistance", rs.data.tomorrow_distance);
          typeof callback=='function' && callback(rs.data)
        },
        ()=>{
          typeof failcallback=='function' && failcallback(rs)
        }
      );
    }
  },
  getters: {},
};
