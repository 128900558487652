import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const bioMatrixScanVuex = createNamespacedHelpers("bioMatrixScan");
import { v4 as uuidv4 } from 'uuid';
export default {
  name: "popBioMatrixScan",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "tonConnect", "setting"]),
    ...bioMatrixScanVuex.mapState(['code'])
  },
  data() {
    return {
      status: 0,
      //0开始 1验证中 2验证完成等待 3重新发起 4已验证 5验证过 6验证失败
      isFlag: true,
      check_count: 2,
      lastTime: 5,
      t: null
    };
  },
  async mounted() {},
  methods: {
    ...bioMatrixScanVuex.mapActions(["faceCheck"]),
    ...bioMatrixScanVuex.mapMutations(["setCode"]),
    close() {
      this.$parent.popBioMatrixScanShow = false;
    },
    submit() {
      if (this.status == 5) {
        this.$parent.popBioMatrixScanShow = false;
        return;
      }
      if (this.status == 3 || this.status == 6) {
        this.isFlag = false;
        this.status = 1;
        this.check_count = 2;
        this.lastTime = 5;
        this.countDown();
        this.setCode(uuidv4());
        this.$router.push('/bioMatrixScanDetail');
        return;
      }
      if (!this.isFlag) return;
      this.isFlag = false;
      this.status = 1;
      this.lastTime = 0;
      this.check_count--;
      let timeStart = new Date().getTime();
      this.faceCheck([this.code, rs => {
        this.isFlag = true;
        clearInterval(this.t);
        this.check_count = 2;
        this.lastTime = 5;
        this.$parent.popBioMatrixScanShow = false;
        this.$nextTick(() => {
          coinAnimFn({
            containerRef: document.getElementById("destoryCoinContainerRef"),
            ref: document.getElementById("coinRef"),
            img: require(`@images/${this.theme}/lucky/usdt.png`),
            radius: 300,
            numCoins: 50,
            callback: () => {}
          }, this);
        });
      }, res => {
        if (res.code == 302) {
          let timeEnd = new Date().getTime();
          let sleep = 3000 - (timeEnd - timeStart);
          setTimeout(() => {
            this.status = 2;
            this.lastTime = 5;
            if (this.check_count > 0) {
              this.countDown();
            } else {
              this.lastTime = 5;
              this.isFlag = true;
              this.status = 3;
            }
          }, sleep > 0 ? sleep : 0);
        } else if (res.code == 201) {
          //已验证
          this.status = 4;
          this.isFlag = true;
          clearInterval(this.t);
          this.check_count = 2;
          this.lastTime = 5;
        } else if (res.code == 409) {
          //被使用
          this.status = 5;
          this.isFlag = true;
          clearInterval(this.t);
          this.check_count = 2;
          this.lastTime = 5;
        } else if (res.code == 401) {
          this.isFlag = true;
          this.status = 6;
        } else {
          //网络错误
          this.isFlag = true;
          this.status = 0;
        }
      }]);
    },
    countDown() {
      this.t = setInterval(() => {
        if (this.lastTime > 0) {
          this.lastTime--;
        }
        if (this.lastTime == 0) {
          clearInterval(this.t);
          this.lastTime = 5;
          this.isFlag = true;
          if (this.check_count == 0) {
            this.status = 3;
          } else {
            this.status = 0;
          }
        }
      }, 1000);
    }
  }
};