var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "loading-spinner"
  }, _vm._l(_vm.dots, function (dot, index) {
    return _c('div', {
      key: index,
      staticClass: "dot",
      style: {
        'background-color': _vm.color
      }
    });
  }), 0);
};
var staticRenderFns = [];
export { render, staticRenderFns };