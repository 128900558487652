import Vue from "vue";
import Vuex from "vuex";

const requireModules = require.context("./modules", false, /.js$/);
const modules = requireModules.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
  modules[moduleName] = requireModules(modulePath).default;
  return modules;
}, {});

Vue.use(Vuex);

export default new Vuex.Store({
  modules,
});
