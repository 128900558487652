const remTran = (val) => Number(val) / 188 + "rem";
const theme = (color) => getComputedStyle(document.body).getPropertyValue(color).trim();

const urlTran = (url) => {
  return require(`${url}`);
};

export default {
  install(Vue) {
    Vue.prototype.remTran = remTran;
    Vue.prototype.theme = theme;
    Vue.prototype.urlTran = urlTran;
  },
};
