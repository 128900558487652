import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const bioMatrixScanVuex = createNamespacedHelpers("bioMatrixScan");
import { v4 as uuidv4 } from 'uuid';
export default {
  name: "bitgetwalletScan",
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", 'setting']),
    ...userVuex.mapState(["userData"]),
    ...bioMatrixScanVuex.mapState(['showPop', 'code'])
  },
  data() {
    return {
      popBioMatrixScanShow: false
    };
  },
  beforeRouteLeave(to, from, next) {
    this.setShowPop(this.popBioMatrixScanShow);
    this.$nextTick(() => {
      next();
    });
  },
  watch: {},
  async mounted() {
    if (this.showPop) {
      this.popBioMatrixScanShow = this.showPop; // 恢复弹窗状态
      this.setShowPop(false);
    }
  },
  methods: {
    ...userVuex.mapMutations(['setUserData']),
    ...bioMatrixScanVuex.mapMutations(['setCode', 'setShowPop']),
    jumpTo(url, show) {
      if (show) {
        this.setCode(uuidv4());
        this.popBioMatrixScanShow = true;
      }
      this.$router.push(url); // 在弹窗打开的情况下进行路由跳转
    }
  }
};