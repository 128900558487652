import Vue from "vue";


export default function vailCode(rs, callback, errcallback) {
  if (rs.code == 0) {
    typeof callback == "function" && callback(rs);
  }
  else if(rs.code == 201 || rs.code == 302 || rs.code == 401 || rs.code == 409){
    typeof errcallback == "function" && errcallback(rs);
  }
  else {
    Vue.toasted.error(rs.message);
    typeof errcallback == "function" && errcallback(rs);
    // Vue.prototype.$loading.hide();
  }
}
