import Vue from "vue";
import vailcode from "@utils/errcode";
import cookie from "js-cookie";
import querystring from "querystring";

export default {
  namespaced: true,
  state: {
    taskList: [],
    advList: [],
    selectTab:0
  },
  mutations: {
    setTaskList: (state, payload)=> {
      state.taskList = payload
    }
    ,
    setAdvList: (state, payload)=> {
      state.advList = payload
    }
    ,
    setSelectTab: (state, payload)=> {
      state.selectTab = payload
    },
    setTaskComplete: (state, payload)=>{
      state.taskList.forEach((item,index) => {
        if(item.id == payload.id){
          state.taskList[index].completed = 1
        }
      });
    }
  },
  actions: {
    getTaskList: async ({ state, commit, dispatch }) => {
      const rs = await Vue.prototype.$http.post("/task/list", { adv: 0});
      vailcode(rs, async () => {
       
        commit('setTaskList',rs.data);
       
      });
    },
    getSubTaskList: async ({ state, commit, dispatch }, [ taskId , callback]) => {
      const rs = await Vue.prototype.$http.post("/task/list", { sub: 1,taskId});
      vailcode(rs, async () => {
       
        typeof callback=='function' && callback(rs.data)
       
      });
    },
    getAdvList: async ({ state, commit, dispatch }) => {
      const rs = await Vue.prototype.$http.post("/task/list", { adv: 1});
      vailcode(rs, async () => {
       
        commit('setAdvList',rs.data);
       
      });
    },
    doTask:async ({ state, commit },[taskId,code,value,callback,failcallback]) => {
      const rs = await Vue.prototype.$http.post("/task/complete",{"taskId":taskId,"code":code,"value":value});
      vailcode(
        rs,
        () => {
          typeof callback=='function' && callback(rs.data)
        },
        ()=>{
          typeof failcallback=='function' && failcallback(rs)
        }
      );
    },
    doSubTask:async ({ state, commit },[taskId,code,value,callback,failcallback]) => {
      const rs = await Vue.prototype.$http.post("/task/complete",{sub:1,"taskId":taskId,"code":code,"value":value});
      vailcode(
        rs,
        () => {
          typeof callback=='function' && callback(rs.data)
        },
        ()=>{
          typeof failcallback=='function' && failcallback(rs)
        }
      );
    },
    taskClick:async ({ state, commit },taskId) => {
      const rs = await Vue.prototype.$http.post("/task/click ",{"taskId":taskId});
    },
  },
  getters: {},
};
