var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "pages",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "bitgetwalletScanDetail page",
    class: _vm.isMobile && 'wap',
    style: {
      backgroundImage: `url(${require(`@images/${_vm.theme}/task-detail-bg.jpg`)})`
    }
  }, [_vm.code ? _c('iframe', {
    staticClass: "iframe",
    attrs: {
      "src": _vm.url + _vm.code,
      "allow": "microphone;camera;"
    }
  }) : _vm._e()])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };